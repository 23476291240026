import React, { useState } from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Nav from "../components/nav"
import Features from "../components/features"
import Blog from "../components/blog"
import Footer from "../components/footer"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default function Index({data}) {

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Layout>
      <Helmet title="Anturec"/>
      <Nav photo={data.header.childImageSharp.fluid} logo={data.logo.childImageSharp.fluid} setIsOpen={setIsOpen}>
      </Nav>
      <Header photo={data.header.childImageSharp.fluid} isOpen={isOpen} setIsOpen={setIsOpen}>
      </Header>
      <Features mission={data.mission.childImageSharp.fluid} therapy={data.therapy.childImageSharp.fluid} glas2={data.glas2.childImageSharp.fluid} pipeline={data.pipeline.childImageSharp.fluid} berdel={data.berdel.publicURL} schwoeppe={data.schwoeppe.publicURL}>
      </Features>
      <Blog>
      </Blog>
      <Footer>
      </Footer>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "header2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mission: file(relativePath: { eq: "Herstellung1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    therapy: file(relativePath: { eq: "Glas1.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pipeline: file(relativePath: { eq: "Herstellung2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    glas2: file(relativePath: { eq: "glas2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logo: file(relativePath: { eq: "logotr.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    berdel: file(relativePath: {eq: "cv_berdel.pdf"}) {
      publicURL
    }
    schwoeppe: file(relativePath: {eq: "cv_schwoeppe.pdf"}) {
      publicURL
    }
  }
`