import React from "react"
import Img from "gatsby-image"

export default function Features({mission, therapy, pipeline, berdel, glas2, schwoeppe}) {
    return (
<section className="bg-white">
    <div id="mission" className="relative max-w-screen-xl my-32 sm:mx-6 sm:p-14 md:mx-20 lg:mx-auto p-10 md:p-28 lg:px-8 lg:py-24">
        <div className="rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 bg-purple-50">
            <div className="relative z-10 bg-purple-50 bg-opacity-90">
                <div className="pt-10 pb-12 px-9 sm:pt-16 sm:px-9 lg:p-26 lg:pr-6 xl:py-20 xl:px-20">
                    <div className="lg:self-center">
                    <h2 className="text-3xl leading-9 font-extralight text-black sm:text-4xl sm:leading-10">
                        Our Mission
                    </h2>
                    <p className="mt-6 text-md leading-7 text-gray-600">ANTUREC's mission embraces research and development in the area of new targeted recombinant drugs against cancer and the translation of promising compounds from the laboratory to the clinic. ANTURECs first <span className="text-red-700">ANti TUmor RECombinant</span> pharmaceutical – the fusion protein tTF-NGR – is in clinical phase II/III studies in oncology. 
In addition to the clinical lead structure tTF-NGR, ANTUREC follows active preclinical programs on other targeted proteins.</p>
                    <div className="flex items-center mt-2">
                        <h3 className="hidden text-xl whitespace-nowrap text-black">Learn more</h3>
                        <div className="mt-3 h-px w-full bg-black"></div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="absolute inset-0 lg:static">
                <Img className="w-full h-full" fluid={mission} />
            </div>
        </div>
    </div>

    <div id="company" className="relative max-w-screen-xl my-32 sm:mx-6 sm:p-14 md:mx-20 lg:mx-auto p-10 md:p-28 lg:px-8 lg:py-24">
        <div className="rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 bg-purple-50 lg:relative">
        <div className="absolute inset-0 lg:static">
                <Img className="w-full h-full" fluid={therapy} />
                <div className="absolute inset-0 w-1/2 hidden lg:block">
                    <div className="absolute right-0 bottom-0 h-48 w-48 mr-2">
                        <Img className="w-full h-full" fluid={glas2} />
                    </div>
                </div>
            </div>
            <div className="relative z-10 bg-purple-50 bg-opacity-90">
                <div className="pt-10 pb-12 px-9 sm:pt-16 sm:px-9 lg:p-26 lg:pr-6 xl:py-20 xl:px-20">
                    <div className="lg:self-center">
                    <h2 className="text-3xl leading-9 font-extralight text-black sm:text-4xl sm:leading-10">
                        Our Company
                    </h2>
                    <p className="mt-6 text-md leading-7 text-gray-600">ANTUREC is a privately owned pharmaceutical company devoted to anticancer
drug development. The company was founded in 2020 by Wolfgang E. Berdel, Christian Schwöppe, and Ari
Bizimis.
</p>
                    <p className="mt-3 text-md leading-7 text-gray-600">ANTUREC's CEOs are Wolfgang E. Berdel and Ari Bizimis, Christian Schwöppe is CSO
of the company. Below, you can find each CV as a PDF.
</p>
<div className="mt-6 prose prose-indigo prose-md text-gray-600">
            <ul>
                <li><a href={berdel} target="_blank">Wolfgang E. Berdel</a></li>
                <li><a href={schwoeppe} target="_blank">Christian Schwöppe</a></li>
                <li><a href="https://www.linkedin.com/in/ari-bizimis-baa49317/" target="_blank">Ari Bizimis</a></li>
            </ul>
        </div>
        </div>
                    </div>
                </div>
            </div>
        </div>


    <div id="science" className="relative max-w-screen-xl my-32 sm:mx-6 sm:p-14 md:mx-20 lg:mx-auto p-10 md:p-28 lg:px-8 lg:py-24">
        <div className="rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 bg-purple-50">
            <div className="relative z-10 bg-purple-50 bg-opacity-90">
                <div className="pt-10 pb-12 px-9 sm:pt-16 sm:px-9 lg:p-26 lg:pr-6 xl:py-20 xl:px-20">
                    <div className="lg:self-center">
                    <h2 className="text-3xl leading-9 font-extralight text-black sm:text-4xl sm:leading-10">
                    Science
                    </h2>
                    <p className="mt-6 text-md leading-7 text-gray-600">By targeting tissue factor to tumor vasculature we aim to selectively occlude tumor
vessels and induce tumor infarction. Our target is aminopeptidase N (CD13) located
on cells of the tumor vasculature and some tumor cells, but absent from most mature
blood vessels. We have designed and studied truncated tissue factor (tTF) with a C-
terminal GNGRAHA peptide (tTF-NGR) binding to CD13 and causing tumor vascular
thrombosis with subsequent tumor infarction.</p>
                    <p className="mt-6 text-md leading-7 text-gray-600">In our clinical studies we combine tTF-NGR with cytotoxic drugs. When tTF-NGR is given at the time of high intratumoral cytotoxic drug levels, the tumor vessels will be occluded resulting in tumor-entrapped drugs. By this mechanism, the antitumor activity of cyctotoxic drugs can be significantly enhanced. Vice versa, the activity of tTF-NGR is increased by the apoptotic action of the cytotoxic drugs on tumor cells and tumor endothelial cells through upregulation of phosphatidylserine. This principle is tested in a first randomized clinical study in soft tissue sarcoma.</p>
                    <p className="mt-6 text-md leading-7 text-gray-600">As tTF-NGR targets the tumor vasculature, which is similar in different tumor types, the principle of entrapping tumor therapeutics by tTF-NGR induced vascular occlusion, if successful in the active trial, can be generalized as a treatment modality for multiple vascularized tumor entities.</p>
                    <div className="flex items-center mt-2">
                        <h3 className="hidden text-xl whitespace-nowrap text-black">Learn more</h3>
                        <div className="mt-3 h-px w-full bg-black"></div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="absolute inset-0 lg:static">
                <Img className="w-full h-full" fluid={pipeline} />
            </div>
        </div>
    </div>

</section>
)}