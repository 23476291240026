import React from "react"

export default function Publications({toogleFunction}) {
    return (
<section className="fixed inset-0 sm:inset-20 md:inset-32 lg:inset-x-auto lg:left-1/2 lg:transform lg:-translate-x-1/2 z-30 bg-white">
    <button
    type="button"
    onClick={toogleFunction}
    className="absolute z-40 top-2 right-6 sm:top-0 sm:-right-12 bg-white"
    >
 <svg className="h-12 w-12 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="miter" strokeLinejoin="miter" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
</svg>

    </button>
    <div className="relative py-16 bg-white overflow-y-auto overflow-x-hidden h-full sm:mt-0">
    <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg className="absolute top-12 left-full transform translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
            <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
            <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg className="absolute bottom-12 left-full transform translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
            <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
        </div>
    </div>
    <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
        <h1>
            <span className="block text-base text-center text-red-600 font-semibold tracking-wide uppercase">ANTUREC</span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Related Publications</span>
        </h1>
        <p className="mt-8 text-xl text-gray-500 leading-8">This list includes preclinical studies, clinical phase I studies, information on "Good Manufacturing Practice" (GMP) guidelines and information on the tTF-NGR working group of the university hospital Muenster. <br></br> The list was last updated on Feb. 2, 2021.</p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <h2>Preclinical Studies</h2>
        <ul>
            <li>Kessler, T.; et al. Inhibition of tumor growth by RGD peptide-directed delivery of truncated tissue factor to the tumor vasculature. Clin Cancer Res. 2005, 11, 6317-6324. <a href="https://doi.org/10.1158/1078-0432.CCR-05-0389">doi:10.1158/1078-0432.CCR-05-0389</a>.</li>
            <li>Persigehl, T.; et al. Antiangiogenic tumor treatment: early noninvasive monitoring with USPIO-enhanced MR imaging in mice. Radiology. 2007, 244, 449–456. <a href="https://doi.org/10.1148/radiol.2442060371">doi: 10.1148/radiol.2442060371</a>.</li>
            <li>Persigehl, T.; et al. Prediction of antiangiogenic treatment eﬃcacy by iron oxide enhanced parametric magnetic resonance imaging. Investig Radiol. 2007, 42, 791–796. <a href="https://doi.org/10.1097/RLI.0b013e3180d5cbd9">doi: 10.1097/RLI.0b013e3180d5cbd9</a>.</li>
            <li>Kessler, T.; et al. Generation of fusion proteins for selective occlusion of tumor vessels. Curr Drug Discov Technol. 2008, 5, 1–8. <a href="https://doi.org/10.2174/157016308783769487">DOI: 10.2174/157016308783769487</a>.</li>
            <li>Bieker, R.; et al. Infarction of tumor vessels by NGR-peptide directed targeting of tissue factor. Experimental results and ﬁrst-in-man experience. Blood. 2009, 113, 5019–5027. <a href="https://doi.org/10.1182/blood-2008-04-150318">DOI: 10.1182/blood-2008-04-150318</a>.</li>
            <li>Schwöppe, C.; et al. Tissue-factor fusion proteins induce occlusion of tumor vessels. Thromb Res. 2010, 125, S143–S150. <a href="https://doi.org/10.1016/S0049-3848(10)70033-5">DOI: 10.1016/S0049-3848(10)70033-5</a>.</li>
            <li>Dreischalück, J.; et al. Vascular infarction by subcutaneous application of tissue factor targeted to tumor vessels with NGR-peptides: activity and toxicity proﬁle. Int J Oncol. 2010, 37, 1389–1397. <a href="https://doi.org/10.3892/ijo_00000790">DOI: 10.3892/ijo_00000790</a>.</li>
            <li>Von Maltzahn, G.; et al. Nanoparticles that communicate in vivo to amplify tumour targeting. Nat Mater. 2011, 10, 545–552. <a href="https://doi.org/10.1038/nmat3049">DOI: 10.1038/nmat3049</a>.</li>
            <li>Schwöppe, C.; et al. Using native gel electrophoresis or isoelectric focusing as experimental “clock” for the (iso)aspartate formation of tTF-NGR fusion proteins. Biomacromol. Mass Spectrom. 2012, 2, 285–293.</li>
            <li>Schwöppe, C.; et al. Anticancer therapy by tumor vessel infarction with polyethylene glycol conjugated retargeted tissue factor. J Med Chem. 2013, 56, 2337–2347. <a href="https://doi.org/10.1021/jm301669z">DOI: 10.1021/jm301669z</a>.</li>
            <li>Persigehl, T.; et al. Non-invasive monitoring of tumor-vessel infarction by retargeted truncated tissue factor tTF-NGR using multi-modal imaging. Angiogenesis. 2014, 17, 235–246. <a href="https://doi.org/10.1007/s10456-013-9391-4">doi: 10.1007/s10456-013-9391-4</a>.</li>
            <li>Brand, C.; et al. Low-energy ultrasound treatment improves regional tumor vessel infarction by retargeted tissue factor. J Ultrasound Med. 2015, 34, 1227–1236. <a href="https://doi.org/10.7863/ultra.34.7.1227">DOI: 10.7863/ultra.34.7.1227</a>.</li>
            <li>Brand, C.; et al. Tumor growth inhibition via occlusion of tumor vasculature induced by N-terminally PEGylated retargeted tissue factor tTF-NGR. Mol Pharm. 2015, 12, 3749–3758. <a href="https://doi.org/10.1021/acs.molpharmaceut.5b00508">DOI: 10.1021/acs.molpharmaceut.5b00508</a>.</li>
            <li>Brand, C.; et al. NG2 proteoglycan as a pericyte target for anticancer therapy by tumor vessel infarction with retargeted tissue factor. Oncotarget. 2016, 7, 6774-6789. <a href="https://doi.org/10.18632/oncotarget.12559">doi: 10.18632/oncotarget.12559</a>.</li>
            <li>Stucke-Ring, J.; et al. Combinatorial eﬀects of doxorubicin and retargeted tissue factor by intratumoral entrapment of doxorubicin and proapoptotic increase of tumor vascular infarction. Oncotarget. 2016, 7, 82458–82472. <a href="https://doi.org/10.18632/oncotarget.12559">DOI: 10.18632/oncotarget.12559</a>.</li>
            <li>Schmidt, L.H.; et al. Potential therapeutic impact of CD13 expression in non-small cell lung cancer. PLoS ONE. 2017, 12, e0177146. <a href="https://doi.org/10.1371/journal.pone.0177146">DOI: 10.1371/journal.pone.0177146</a>.</li>
            <li>Schmidt, L.H.; et al. CD13 as target for tissue factor induced tumor vascular infarction in small cell lung cancer. Lung Cancer. 2017, 113, 121–127. <a href="http://dx.doi.org/10.1016/j.lungcan.2017.09.013">DOI: 10.1016/j.lungcan.2017.09.013</a>.</li>
            <li>Kessler, T.; et al. Aminopeptidase N (CD13): Expression, prognostic impact, and use as therapeutic target for tissue factor induced tumor vascular infarction in soft tissue sarcoma. Transl Oncol. 2018, 11, 1271–1282. <a href="https://doi.org/10.1016/j.tranon.2018.08.004">DOI: 10.1016/j.tranon.2018.08.004</a>.</li>
            <li>Höink, A.; et al. Gadofosveset-enhanced MRI as simple surrogate parameter for real-time evaluation of the initial tumour vessel infarction by retargeted tissue factor tTF-NGR. Oncol Lett. 2019, 17, 270–280. <a href="https://doi.org/10.3892/ol.2018.9638">DOI: 10.3892/ol.2018.9638</a>.</li>
            <li>Brand, C.; et al. Radiation synergizes with antitumor activity of CD13-targeted tissue factor in a HT1080 xenograft model of human soft tissue sarcoma. PLoS ONE. 2020, 15, e0229271. <a href="https://doi.org/10.1371/journal.pone.0229271">DOI: 10.1371/journal.pone.0229271</a>.</li>
        </ul>
        <h2>Clinical Phase I Studies</h2>
        <ul>
            <li>Gerwing, M.; et al. The beginning of the end for conventional RECIST—novel therapies require novel imaging approaches. Nat Rev Clin Oncol. 2019, 16, 442–458. <a href="https://doi.org/10.1038/s41571-019-0169-5">DOI: 10.1038/s41571-019-0169-5</a>.</li>
            <li>Schliemann, C.; et al. First-in-class CD13-targeted tissue factor tTF-NGR in patients with recurrent or refractory malignant tumors: results of a phase I dose-escalation study. Cancers. 2020, 12, 1488. <a href="https://doi.org/10.3390/cancers12061488">DOI: 10.3390/cancers12061488</a>.</li>
            <li>Berdel, W.E.; et al. Animal safety, toxicology, and pharmacokinetic studies according to the ICH S9 guideline for a novel fusion protein tTF-NGR targeting procoagulatory activity into tumor vasculature: are results predictive for humans? Cancers. 2020, 12, 3536. <a href="https://doi.org/10.3390/cancers12123536">doi:10.3390/cancers12123536</a>.</li>
        </ul>
        <h2>Information on Good Manufacturing Practice (GMP) Guidelines</h2>
        <ul>
            <li><a href="https://ec.europa.eu/health/documents/eudralex/vol-4_en">EU GMP Guidelines</a></li>
            <li><a href="https://www.gesetze-im-internet.de/amg_1976/">AMG</a></li>
            <li><a href="https://www.gesetze-im-internet.de/amwhv/">AMWHV</a></li>
        </ul>
        <h2>tTF-NGR Working Group of the University Hospital Muenster</h2>
        <ul>
            <li><a href="https://www.ukm.de/index.php?id=11311">Website</a></li>
        </ul>
        </div>
    </div>
    </div>
</section>

)}