import React from "react"

export default function Stats() {
    return (

<footer className="bg-gray-900">
  <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
    <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
      <div className="px-5 py-2">
        <a href="/impressum" className="text-base text-gray-100 hover:text-gray-400">
        Impressum – Legal Notice
        </a>
      </div>
    </nav>
    <p className="mt-8 text-center text-sm text-gray-100">
      © 2021 ANTUREC. All rights reserved.
    </p>
  </div>
</footer>


)}