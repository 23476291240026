import React, { useState } from "react"
import Img from "gatsby-image"
import LatestNews from "./resources/latest-news";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function Header({photo, isOpen, setIsOpen}) {

    function toggleArticle() {
        setIsOpen(!isOpen);
        if(!isOpen) {
            disableBodyScroll(document.body);
        } else {
            enableBodyScroll(document.body);
        }
    }
    return (
<section className="relative h-screen w-full" style={{minHeight: "700px"}}>
    <div className="absolute z-40 inset-0 max-w-screen-xl mx-auto flex flex-col h-full">
            <div className="m-auto text-center mx-6 sm:mx-2 pt-12">
                <h2 className="text-2xl leading-2 tracking-tight font-bold text-white sm:text-5xl sm:leading-none md:text-6xl">
                ANTUREC Pharmaceuticals
                </h2>
                <p className="mt-3 text-base text-white sm:mt-5 sm:text-xl sm:max-w-xl sm:mx-auto md:mt-5 md:text-2xl">
                ANTUREC's research has the aim to improve cancer outcome by therapy with new recombinant and targeted drugs.
                </p>
                <svg className="fill-current text-white h-10 w-10 m-auto mt-8 animate-bounce" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M16 17l-4 4m0 0l-4-4m4 4V4" />
                </svg>
            </div>
            <div className="flex flex-row items-start justify-start md:justify-around max-w-screen-xl mx-2 mb-4 sm:mb-8">
                <div className="flex w-1/2 md:w-2/6">
                    <h1 className="pl-2 mr-2 sm:mr-0 md:mr-2 text-2xl sm:text-3xl font-light text-white">Latest</h1>
                    <h1 className="text-2xl sm:text-3xl font-light text-white">News</h1>
                </div>
                <div className={`${isOpen ? ' block absolute z-50' : 'hidden'}`}>
                    <LatestNews toogleFunction={toggleArticle}>
                    </LatestNews>
                </div>
                <div id="test" className={`${isOpen ? 'block' : 'hidden'} z-40 fixed inset-0 bg-gray-900 opacity-60`}>
                </div>
                <div className="w-1/2 md:w-2/6 flex justify-start">
                    <h1 className="w-full underline  text-md font-normal text-white sm:text-lg sm:leading-8"><button onClick={toggleArticle}>The ANTUREC Pharmaceuticals team announces new progress</button></h1>
                    {/*<p className="w-8/12 sm:w-full text-md leading-6 text-white sm:text-md text-justify md:text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>*/}
                </div>
                {/*
                <div className="w-full flex mb-4 sm:mb-0 justify-around sm:flex-col sm:w-2/6 md:w-1/5 lg:w-3/12">
                    <h1 className="w-3/12 sm:w-full text-md font-normal text-white sm:text-lg sm:leading-8">New Article One</h1>
                    <p className="w-8/12 sm:w-full text-md leading-6 text-white sm:text-md text-justify md:text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
                <div className="w-full mb-4 sm:mb-0 justify-around sm:flex-col sm:w-1/5 lg:w-3/12 hidden md:flex">
                    <h1 className="w-3/12 sm:w-full text-md font-normal text-white sm:text-lg sm:leading-8">New Article One</h1>
                    <p className="w-8/12 sm:w-full text-md leading-6 text-white sm:text-md text-justify md:text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
                */}
            </div>
        </div>
    <div className="h-full w-full bg-black">
        <Img className="w-full h-full opacity-80" fluid={photo} />
    </div>
</section>
)}