import React, {useEffect, useState} from "react"
import Publications from "../components/resources/publications"
import { Transition } from '@headlessui/react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function Blog() {
  const [isOpen, setIsOpen] = useState(false)

  function toggleArticle() {
      setIsOpen(!isOpen);
  }
    return (
      <>
    <div class="relative max-w-7xl mx-auto pt-12">
    <div class="text-center">
      <h2 class="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl mb-4">
        ANTUREC - Resources
      </h2>
    </div>
    </div>
<button type="button" onClick={toggleArticle}>
  <div id="test" className={`${isOpen ? 'block' : 'hidden'} z-20 fixed inset-0 bg-gray-900 opacity-60`}>
  </div>
</button>
<div id="resources" className="bg-white pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
  <div className="relative max-w-lg mx-auto lg:max-w-7xl">
    
    <div className="grid gap-16 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
      <div>
        <div className={`${isOpen ? 'block' : 'hidden'}`}>
          <Publications toogleFunction={toggleArticle}>
          </Publications>
        </div>
        
        <button type="button" onClick={toggleArticle} className="block text-left">
          <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
            ANTUREC related publications
          </h3>
          <p className="mt-3 text-base leading-6 text-gray-500">
            This lists different resources about ANTUREC and it's science. This includes preclinical studies, clinical phase 1 studies and information on "Good Manufacturing Practice" guidelines.
          </p>

          <div className="mt-6 flex items-center">
            <div className="">
              <p className="text-sm leading-5 font-medium text-gray-900">
                  Wolfgang E. Berdel and Christian Schwöppe
              </p>
              <div className="flex text-sm leading-5 text-gray-500">
                <time dateTime="2020-03-16">
                  Feb 7, 2021
                </time>
                <span className="mx-1">
                  ·
                </span>
                <span>
                  2 min read
                </span>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
</>
    )}