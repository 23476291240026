import React, {useEffect, useState} from "react"
import { Transition } from '@headlessui/react'
import Img from "gatsby-image"
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function Nav({photo, logo, setIsOpen}) {
    const [isNavOpen, setIsNavOpen] = useState(false)

    const width = useWindowWidth();
    const breakpoint = 1024;

    useEffect(() => {
        if(width >= breakpoint) {
            setIsNavOpen(false);
            enableBodyScroll(document.body);
        }
    }, [width]);

    function toggleMenu() {
        setIsNavOpen(!isNavOpen);
        setIsOpen(false);
        if(!isNavOpen) {
            disableBodyScroll(document.body);
        } else {
            enableBodyScroll(document.body);
        }
        return true;
    }

    function toggleNavLink() {
        enableBodyScroll(document.body);
        setIsOpen(false);
    }

    return (
<section className={`${isNavOpen ? 'h-screen' : 'h-16'} lg:h-16 w-full fixed z-50`}>
<div className="absolute max-w-screen-xl mx-auto inset-0 pb-8 sm:pb-16 w-full lg:pb-28 xl:pb-32 h-full">
    <div className="relative pt-3 px-4 sm:px-6 lg:px-8">
        <nav className="relative flex items-center justify-between h-10">
        <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full lg:w-auto">
                <a className={`${isNavOpen ? 'opacity-0' : 'opacity-100'} lg:w-80 sm:w-72 w-64 z-30`} href="/">
                <Img fluid={logo} />
                </a>
                <div className="-mr-2 flex items-center lg:hidden z-50">
                    <button
                    type="button"
                    onClick={toggleMenu}
                    className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-white transition duration-150 ease-in-out"
                    id="main-menu"
                    aria-label="Main menu"
                    aria-haspopup="true"
                    >
                    <svg
                        className="h-6 w-6"
                        stroke={`${isNavOpen ? 'black' : 'white'}`}
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                    </button>
                </div>
            </div>
        </div>
        <div className="hidden lg:block lg:ml-10 lg:pr-4 z-50">
            <a
            href="#mission"
            onClick={toggleNavLink}
            className="font-medium text-white hover:text-gray-900 transition duration-150 ease-in-out"
            >
            Our Mission
            </a>
            <a
            href="#company"
            onClick={toggleNavLink}
            className="ml-8 font-medium text-white hover:text-gray-900 transition duration-150 ease-in-out"
            >
            Our Company
            </a>
            <a
            href="#science"
            onClick={toggleNavLink}
            className="ml-8 font-medium text-white hover:text-gray-900 transition duration-150 ease-in-out"
            >
            Science
            </a>
            <a
            href="#resources"
            onClick={toggleNavLink}
            className="ml-8 font-medium text-white hover:text-gray-900 transition duration-150 ease-in-out"
            >
            Resources
            </a>
        </div>
        </nav>
    </div>
    <Transition
    show={isNavOpen}
    enter="transform transition ease-in-out duration-500"
    enterFrom="-translate-y-full"
    enterTo="-translate-y-0"
    leave="transform transition ease-in-out duration-500"
    leaveFrom="-translate-y-0"
    leaveTo="-translate-y-full"
    >
            {(ref) => (
            <div ref={ref} className="absolute z-40 top-0 inset-x-0 lg:hidden h-screen">
            <div className="h-full">
                <div
                className="bg-white shadow-xs h-full overflow-hidden"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
                >
                <div className="flex flex-col items-center h-full justify-center">
                <a
                    href="#mission"
                    onClick={toggleMenu}
                    className="block px-6 py-4 rounded-md text-2xl text-gray-600 hover:text-white hover:bg-gray-600 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                >
                    Our Mission
                </a>
                <a
                    href="#company"
                    onClick={toggleMenu}
                    className="mt-1 block px-6 py-4 rounded-md text-2xl text-gray-600 hover:text-white hover:bg-gray-600 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                >
                    Our Company
                </a>
                <a
                    href="#science"
                    onClick={toggleMenu}
                    className="mt-1 block px-6 py-4 rounded-md text-2xl text-gray-600 hover:text-white hover:bg-gray-600 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                >
                    Science
                </a>
                <a
                    href="#resources"
                    onClick={toggleMenu}
                    className="mt-1 block px-6 py-4 rounded-md text-2xl text-gray-600 hover:text-white hover:bg-gray-600 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                >
                    Resources
                </a>
                </div>
            </div>
            </div>
        </div>
            )}
    </Transition>
</div>
    <div className="h-16 w-full bg-black overflow-hidden" >
        <Img className="w-full h-screen opacity-80" style={{minHeight: "700px"}} fluid={photo} />
    </div>
</section>
)}

function useWindowWidth() {
    const [width, setWidth] = useState(undefined);
  
    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      
      window.addEventListener("resize", handleResize);
      
      handleResize();
      
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return width;
  }