import React from "react"

export default function LatestNews({ toogleFunction }) {
    return (
        <section className="fixed inset-0 top-16 sm:inset-20 md:inset-32 lg:inset-x-auto lg:left-1/2 lg:transform lg:-translate-x-1/2 z-50 bg-white">
            <button
                type="button"
                onClick={toogleFunction}
                className="absolute z-50 top-2 right-6 sm:top-0 sm:-right-12 bg-white"
            >
                <svg className="h-12 w-12 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="miter" strokeLinejoin="miter" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>

            </button>
            <div className="relative py-16 bg-white overflow-y-auto overflow-x-hidden h-full sm:mt-0">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                    <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                        <svg className="absolute top-12 left-full transform translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg className="absolute bottom-12 left-full transform translate-x-32" width={404} height={384} fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                        </svg>
                    </div>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h1>
                            <span className="block text-base text-center text-red-600 font-semibold tracking-wide uppercase">ANTUREC</span>
                            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">New Progress</span>
                        </h1>
                        <p className="mt-8 text-xl text-gray-500 leading-8">The ANTUREC Pharmaceuticals team has made the following progress:
                        </p>
                    </div>
                    <div className="mt-8 ml-4 prose prose-indigo prose-lg text-gray-500 mx-auto">
                        <p>1. Manufacturer´s authorization for tTF-NGR renewed: 2021-05-17</p>
                    </div>
                    <div className="mt-8 ml-4 prose prose-indigo prose-lg text-gray-500 mx-auto">
                        <p>2. Phase III study in patients with sarcomas approved by:</p>
                        <ul>
                            <li>Paul-Ehrlich-Institute: 2021-09-17</li>
                            <li>Ethical Board: 2021-10-15</li>
                        </ul>
                    </div>
                    <div className="mt-8 ml-4 prose prose-indigo prose-lg text-gray-500 mx-auto">
                        <p>3. First patient on phase III study: 2021-11-12
                        </p>
                    </div>
                </div>
            </div>
        </section>

    )
}